import { doc, setDoc, getDoc, collection, query, getDocs } from 'firebase/firestore';
import { db } from '../config/firebase';
import { Activity } from '../types/activity';
import { Category } from '../types/category';
import { ActivityType } from '../types/activityType';
import { GameProgress } from '../types/game.d';

// User Profile
export async function saveUserProfile(userId: string, data: any) {
  try {
    await setDoc(doc(db, 'users', userId), {
      ...data,
      updatedAt: new Date()
    }, { merge: true });
  } catch (error) {
    console.error('Error saving user profile:', error);
    throw error;
  }
}

// Activities
export async function saveActivity(userId: string, activity: Partial<Activity> & { id?: string }) {
  try {
    const activityRef = activity.id 
      ? doc(db, `users/${userId}/activities/${activity.id}`)
      : doc(collection(db, `users/${userId}/activities`));

    const activityData = {
      ...activity,
      updatedAt: new Date(),
      ...(activity.id ? {} : { createdAt: new Date() })
    };

    await setDoc(activityRef, activityData);
    return activityRef.id;
  } catch (error) {
    console.error('Error saving activity:', error);
    throw error;
  }
}

export async function getUserActivities(userId: string) {
  try {
    const activitiesRef = collection(db, `users/${userId}/activities`);
    const q = query(activitiesRef);
    const snapshot = await getDocs(q);
    return snapshot.docs
      .filter(doc => !doc.data().deleted)
      .map(doc => ({
        id: doc.id,
        ...doc.data(),
        date: doc.data().date.toDate()
      })) as Activity[];
  } catch (error) {
    console.error('Error getting user activities:', error);
    throw error;
  }
}

// Settings
export async function saveUserSettings(userId: string, settings: {
  categories: Category[];
  activityTypes: ActivityType[];
  theme: string;
}) {
  try {
    await setDoc(doc(db, `users/${userId}/settings/preferences`), {
      ...settings,
      updatedAt: new Date()
    });
  } catch (error) {
    console.error('Error saving user settings:', error);
    throw error;
  }
}

export async function getUserSettings(userId: string) {
  try {
    const settingsDoc = await getDoc(doc(db, `users/${userId}/settings/preferences`));
    return settingsDoc.exists() ? settingsDoc.data() : null;
  } catch (error) {
    console.error('Error getting user settings:', error);
    throw error;
  }
}

// Activity Suggestions
export async function saveSuggestions(userId: string, suggestions: any[]) {
  try {
    await setDoc(doc(db, `users/${userId}/suggestions/data`), {
      suggestions,
      updatedAt: new Date()
    });
  } catch (error) {
    console.error('Error saving suggestions:', error);
    throw error;
  }
}

export async function getUserSuggestions(userId: string) {
  try {
    const suggestionsDoc = await getDoc(doc(db, `users/${userId}/suggestions/data`));
    return suggestionsDoc.exists() ? suggestionsDoc.data().suggestions : [];
  } catch (error) {
    console.error('Error getting user suggestions:', error);
    throw error;
  }
}

// Game Progress
export async function saveGameProgress(userId: string, progress: GameProgress) {
  try {
    await setDoc(doc(db, 'gameProgress', userId), {
      ...progress,
      updatedAt: new Date()
    }, { merge: true });
  } catch (error) {
    console.error('Error saving game progress:', error);
    throw error;
  }
}

export async function getGameProgress(userId: string): Promise<GameProgress | null> {
  try {
    const docSnap = await getDoc(doc(db, 'gameProgress', userId));
    if (docSnap.exists()) {
      return docSnap.data() as GameProgress;
    } else {
      console.warn('No game progress found for user:', userId);
      return null;
    }
  } catch (error) {
    console.error('Error retrieving game progress:', error);
    throw error;
  }
}

export async function getGameProgressForGame(userId: string, gameId: string): Promise<GameProgress | null> {
  try {
    const progressRef = doc(db, `users/${userId}/gameProgress/${gameId}`);
    const progressDoc = await getDoc(progressRef);
    
    if (progressDoc.exists()) {
      const data = progressDoc.data();
      if (data && data.settings && data.stats) { 
        return data as GameProgress;
      } else {
        console.warn(`Game progress for gameId ${gameId} is missing required properties.`);
        return null;
      }
    }
    
    return null;
  } catch (error) {
    console.error('Error getting game progress:', error);
    throw error;
  }
}

export async function saveGameProgressForGame(userId: string, gameId: string, progress: GameProgress) {
  try {
    const progressRef = doc(db, `users/${userId}/gameProgress/${gameId}`);
    await setDoc(progressRef, progress, { merge: true });
  } catch (error) {
    console.error('Error saving game progress:', error);
    throw error;
  }
}

// Initialize user data
export async function initializeUserData(userId: string, email: string | null) {
  try {
    const userDoc = await getDoc(doc(db, 'users', userId));
    
    if (!userDoc.exists()) {
      await setDoc(doc(db, 'users', userId), {
        email,
        createdAt: new Date(),
        updatedAt: new Date()
      });

      // Initialize default settings
      await saveUserSettings(userId, {
        categories: [
          { id: '1', name: 'Park Visit', type: 'standard' },
          { id: '2', name: 'Sports', type: 'standard' },
          { id: '3', name: 'Educational', type: 'standard' },
          { id: '4', name: 'Dax (4)', type: 'children' },
          { id: '5', name: 'Hazel (2)', type: 'children' }
        ],
        activityTypes: [
          { id: '1', name: 'Indoor Play' },
          { id: '2', name: 'Outdoor Adventure' },
          { id: '3', name: 'Educational' },
          { id: '4', name: 'Arts & Crafts' },
          { id: '5', name: 'Sports' },
          { id: '6', name: 'Music & Dance' }
        ],
        theme: 'dark'
      });
    }
  } catch (error) {
    console.error('Error initializing user data:', error);
    throw error;
  }
}