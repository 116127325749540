import { useEffect, Suspense, lazy } from 'react';
import { Routes, Route, Navigate, Outlet } from 'react-router-dom';
import { useAuth } from './hooks/useAuth'; // Ensure useAuth is correctly imported
import ProtectedRoute from './components/ProtectedRoute';
import { Toaster } from 'react-hot-toast';

// Lazy load pages to improve initial load performance
const Home = lazy(() => import('./pages/Home'));
const Summary = lazy(() => import('./pages/Summary'));
const Activities = lazy(() => import('./pages/Activities'));
const FindActivities = lazy(() => import('./pages/FindActivities'));
const Settings = lazy(() => import('./pages/Settings'));
const Login = lazy(() => import('./pages/Login'));
const ActivityForm = lazy(() => import('./pages/ActivityForm'));
const DiyActivities = lazy(() => import('./pages/DiyActivities'));
const RainbowDance = lazy(() => import('./pages/games/RainbowDance'));

function LoadingFallback() {
  return (
    <div className="flex items-center justify-center min-h-screen">
      <div className="w-12 h-12 border-4 border-primary-600 rounded-full animate-spin border-t-transparent"></div>
    </div>
  );
}

export default function App() {
  const { user, loading, error, isGuest } = useAuth(); // Ensure useAuth is correctly used

  useEffect(() => {
    console.log('App component mounted');
    console.log('Authentication state:', { 
      user: !!user, 
      loading, 
      error,
      isGuest
    });

    if (error) {
      console.error('Global authentication error:', error);
    }
  }, [user, loading, error, isGuest]);

  if (loading) {
    return <LoadingFallback />;
  }

  if (error) {
    return (
      <div className="flex items-center justify-center min-h-screen bg-red-100">
        <div className="text-center text-red-600">
          <h2 className="text-2xl font-bold mb-4">Application Error</h2>
          <p>{error}</p>
          <button 
            onClick={() => window.location.reload()}
            className="mt-4 px-4 py-2 bg-blue-500 text-white rounded"
          >
            Reload Application
          </button>
        </div>
      </div>
    );
  }

  return (
    <>
      <Suspense fallback={<LoadingFallback />}>
        <Routes>
          <Route
            path="/login"
            element={
              user ? <Navigate to="/summary" replace /> : (
                <Login />
              )
            }
          />
          
          <Route element={<ProtectedRoute><Outlet /></ProtectedRoute>}>
            <Route path="/" element={<Home />} />
            <Route path="/summary" element={<Summary />} />
            <Route path="/activities" element={<Activities />} />
            <Route path="/activity/new" element={<ActivityForm />} />
            <Route path="/activity/:id" element={<ActivityForm />} />
            <Route path="/find-activities" element={<FindActivities />} />
            <Route path="/diy-activities" element={<DiyActivities />} />
            <Route path="/diy-activities/rainbow-dance" element={<RainbowDance />} />
            <Route path="/settings" element={<Settings />} />
          </Route>
          
          <Route path="*" element={<Navigate to="/" replace />} />
        </Routes>
      </Suspense>

      <Toaster 
        position="top-right"
        toastOptions={{
          success: {
            style: {
              background: '#4CAF50',
              color: 'white',
            },
            iconTheme: {
              primary: 'white',
              secondary: '#4CAF50',
            },
          },
          error: {
            style: {
              background: '#F44336',
              color: 'white',
            },
            iconTheme: {
              primary: 'white',
              secondary: '#F44336',
            },
          },
          style: {
            background: '#333',
            color: '#fff',
            borderRadius: '8px',
            boxShadow: '0 4px 6px rgba(0,0,0,0.1)',
          },
        }}
      />
    </>
  );
}