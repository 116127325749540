import React, { useEffect } from 'react';
import { Navigate, Outlet, useLocation } from 'react-router-dom';
import { useAuth } from '../hooks/useAuth';

interface ProtectedRouteProps {
  children: React.ReactNode;
}

export default function ProtectedRoute({ children }: ProtectedRouteProps) {
  const { user, isGuest, loading, error } = useAuth();
  const location = useLocation();

  useEffect(() => {
    console.log('ProtectedRoute component mounted');
    console.log('Authentication state:', { 
      user: !!user, 
      isGuest, 
      loading, 
      error,
      currentPath: location.pathname
    });

    if (error) {
      console.error('Authentication error:', error);
    }
  }, [user, isGuest, loading, error, location]);

  if (loading) {
    console.log('Loading state in ProtectedRoute');
    return (
      <div className="flex items-center justify-center min-h-screen">
        <div className="w-12 h-12 border-4 border-primary-600 rounded-full animate-spin border-t-transparent"></div>
      </div>
    );
  }

  if (error) {
    console.error('Authentication error:', error);
    return (
      <div className="flex items-center justify-center min-h-screen bg-red-100">
        <div className="text-center text-red-600">
          <h2 className="text-2xl font-bold mb-4">Authentication Error</h2>
          <p>{error}</p>
          <button 
            onClick={() => window.location.reload()}
            className="mt-4 px-4 py-2 bg-blue-500 text-white rounded"
          >
            Retry
          </button>
        </div>
      </div>
    );
  }

  // Allow access if authenticated or in guest mode
  if (user || isGuest) {
    return children ? <>{children}</> : <Outlet />;
  }

  // Redirect to login if not authenticated and not in guest mode
  return <Navigate to="/login" state={{ from: location }} replace />;
}