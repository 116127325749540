import { useState, useEffect, useCallback, useMemo } from 'react';
import { auth, googleProvider } from '../config/firebase';
import { 
  User, 
  signInWithEmailAndPassword, 
  createUserWithEmailAndPassword, 
  signOut,
  signInWithRedirect,
  updateProfile,
  updatePassword,
  onAuthStateChanged
} from 'firebase/auth';
import { initializeUserData } from '../services/firebase';

export function useAuth() {
  const [user, setUser] = useState<User | null>(null);
  const [isGuest, setIsGuest] = useState<boolean>(() => {
    return localStorage.getItem('isGuest') === 'true' || true;
  });
  const [loading, setLoading] = useState(true);
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState<string | null>(null);
  const [isInitialized, setIsInitialized] = useState(false);

  const initializeAuth = useCallback(async () => {
    try {
      console.log('Initializing authentication state');
      const unsubscribe = onAuthStateChanged(
        auth, 
        async (user) => {
          try {
            console.log('Auth state changed:', user ? 'User logged in' : 'No user');
            if (user) {
              setUser(user);
              setIsGuest(false);
              localStorage.removeItem('isGuest');
              console.log('User data:', user);
              try {
                await initializeUserData(user.uid, user.email);
              } catch (initError) {
                console.error('Error initializing user data:', initError);
                setError('Failed to initialize user data');
              }
            } else {
              setUser(null);
              if (!isGuest) {
                continueAsGuest();
              }
            }
            setLoading(false);
            setError(null);
          } catch (authError) {
            console.error('Authentication processing error:', authError);
            setError('An unexpected error occurred during authentication');
            setLoading(false);
          }
        },
        (authError) => {
          console.error('Auth state change error:', authError);
          setError(authError.message || 'Authentication failed');
          setLoading(false);
        }
      );
      return () => unsubscribe();
    } catch (err) {
      console.error('Error during authentication initialization:', err);
      setError('Failed to initialize authentication');
      setLoading(false);
    }
  }, [isGuest]);

  useEffect(() => {
    if (!isInitialized) {
      (async () => {
        try {
          await initializeAuth();
          setIsInitialized(true);
        } catch (error) {
          console.error('Error initializing authentication:', error);
          setLoading(false); // Ensure loading state is updated on error
        }
      })();
    }
  }, [isInitialized]);

  useEffect(() => {
    console.log('Auth state:', { user, isGuest, loading });
    if (loading) {
      console.log('Loading user data...');
    } else if (!user) {
      console.warn('No user found, redirecting to login');
      // Redirect to login or show unauthorized message
    } else {
      console.log('User authenticated:', user);
    }
  }, [loading, user, isGuest]);

  useEffect(() => {
    if (!loading && user === null && !isGuest) {
      console.log('Enabling guest mode');
      continueAsGuest();
    }
  }, [loading, user, isGuest]);

  useEffect(() => {
    console.log('useAuth hook re-rendered');
  });

  const login = async (email: string, password: string) => {
    setIsLoading(true);
    setError(null);
    try {
      const result = await signInWithEmailAndPassword(auth, email, password);
      setIsGuest(false);
      setError(null);
      return result.user;
    } catch (loginError: any) {
      console.error('Login error:', loginError);
      setError(loginError.message || 'Login failed');
      throw loginError;
    } finally {
      setIsLoading(false);
    }
  };

  const loginWithGoogle = async () => {
    setIsLoading(true);
    setError(null);
    try {
      // Use redirect for better cross-platform support
      await signInWithRedirect(auth, googleProvider);
      // Note: The actual result will be handled by onAuthStateChanged
    } catch (googleLoginError: any) {
      console.error('Google sign in error:', googleLoginError);
      setError(googleLoginError.message || 'Google sign in failed');
      throw googleLoginError;
    } finally {
      setIsLoading(false);
    }
  };

  const signup = async (email: string, password: string, profile: { name: string }) => {
    setIsLoading(true);
    setError(null);
    try {
      const result = await createUserWithEmailAndPassword(auth, email, password);
      await updateProfile(result.user, { displayName: profile.name });
      setIsGuest(false);
      setError(null);
      return result.user;
    } catch (signupError: any) {
      console.error('Signup error:', signupError);
      setError(signupError.message || 'Signup failed');
      throw signupError;
    } finally {
      setIsLoading(false);
    }
  };

  const updateUserProfile = async (profile: { displayName?: string; photoURL?: string }) => {
    if (!user) return;
    setIsLoading(true);
    setError(null);
    try {
      await updateProfile(user, profile);
      setUser({ ...user, ...profile });
    } catch (updateError) {
      console.error('Update profile error:', updateError);
      setError('Failed to update profile');
      throw updateError;
    } finally {
      setIsLoading(false);
    }
  };

  const updateUserPassword = async (newPassword: string) => {
    if (!user) return;
    setIsLoading(true);
    setError(null);
    try {
      await updatePassword(user, newPassword);
    } catch (passwordError) {
      console.error('Update password error:', passwordError);
      setError('Failed to update password');
      throw passwordError;
    } finally {
      setIsLoading(false);
    }
  };

  const logout = async () => {
    setIsLoading(true);
    setError(null);
    try {
      await signOut(auth);
      setUser(null);
      setIsGuest(false);
      continueAsGuest(); // Auto-enable guest mode after logout
    } catch (logoutError) {
      console.error('Logout error:', logoutError);
      setError('Failed to sign out');
      throw logoutError;
    } finally {
      setIsLoading(false);
    }
  };

  const continueAsGuest = () => {
    console.log('Continuing as guest');
    setIsGuest(true);
    setUser(null);
    localStorage.setItem('isGuest', 'true');
  };

  const authState = useMemo(() => ({
    user,
    isGuest,
    loading,
    isLoading,
    error,
    login,
    loginWithGoogle,
    signup,
    logout,
    continueAsGuest,
    updateUserProfile,
    updateUserPassword
  }), [user, isGuest, loading, isLoading, error]);

  return authState;
}